/* Follow Tailwind convention */
@use 'sass:color';

.ring-4,
:focus.focus\:ring-4,
:hover.focus\:ring-4 {
  --tw-ring-inset: ; /* stylelint-disable-line -- Must be included for composition to work */
  --tw-ring-offset-width: 0px; /* stylelint-disable-line length-zero-no-unit -- Must include unit for calc() to work */
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 50%);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
}

.resize-none {
  resize: none;
}

.cursor-pointer {
  cursor: pointer;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-none {
  flex: none;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.basis-4\/5 {
  flex-basis: 80%;
}

.basis-1\/5 {
  flex-basis: 20%;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.grid {
  display: grid;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.gap-y-px {
  row-gap: 1px;
}

.gap-x-px {
  column-gap: 1px;
}

.gap-6 {
  gap: 1.5rem;
}

.m-6 {
  margin: 1.5rem;
}

.m-20 {
  margin: 5rem;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-9 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-1\.5 {
  margin-right: .375rem !important;
}

.me-4 {
  margin-right: 1rem;
}

.me-6 {
  margin-right: 1.5rem;
}

.me-10 {
  margin-right: 2.5rem;
}

.me-12 {
  margin-right: 3rem;
}

.ms-0 {
  margin-left: 0;
}

.ms-1 {
  margin-left: .25rem;
}

.ms-1\.5 {
  margin-left: .375rem;
}

.ms-2 {
  margin-left: .5rem;
}

.ms-4 {
  margin-left: 1rem;
}

.ms-6 {
  margin-left: 1.5rem;
}

.ms-7 {
  margin-left: 1.75rem;
}

.ms-12 {
  margin-left: 3rem;
}

.ms-auto {
  margin-left: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-44 {
  margin-top: 11rem;
}

.mt-5vh {
  margin-top: 5vh;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0_5 {
  margin-bottom: 0.125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.-mb-px {
  margin-bottom: -1px;
}

.-mt-14 {
  margin-top: -3.5rem;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-10 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-6 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.px-8 {
  padding-right: 2rem;
  padding-left: 2rem;
}

.px-5vw {
  padding-right: 5vw;
  padding-left: 5vw;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: .5rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pb-0\.5 {
  padding-bottom: .125rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.ps-2 {
  padding-left: .5rem;
}

.ps-5 {
  padding-left: 1.25rem !important;
}

.ps-12 {
  padding-left: 3rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  margin-right: 0;
  margin-left: 1rem;
}

.space-x-12 > :not([hidden]) ~ :not([hidden]) {
  margin-right: 0;
  margin-left: 3rem;
}

.h-6 {
  height: 1.5rem;
}

.h-10 {
  height: 2.5rem;
}

.h-24 {
  height: 6rem;
}

.h-32px {
  height: 32px;
}

.h-100vh {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.max-h-48 {
  max-height: 48px;
}

.max-h-80 {
  max-height: 80px;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 0.5rem;
}

.w-10 {
  width: 2.5rem;
}

.w-24 {
  width: 6rem;
}

.w-52 {
  width: 13rem;
}

.w-60 {
  width: 15rem;
}

.w-80 {
  width: 20rem;
}

.w-522 {
  width: 522px;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-4\/10 {
  width: 40%;
}

.w-1\/2 {
  width: 50%;
}

.w-6\/10 {
  width: 60%;
}

.w-full {
  width: 100%;
}

.max-w-md {
  max-width: 26rem;
}

.max-w-full {
  max-width: 100%;
}

.leading-none {
  line-height: 1;
}

.leading-normal {
  line-height: 1.5;
}

.text-decoration-none {
  text-decoration: none;
}

.hover\:text-decoration-none:hover {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-0_7 {
  font-size: .7rem;
}

.text-0_75 {
  font-size: .75rem;
}

.text-0_8125 {
  font-size: .8125rem;
}

.text-1 {
  font-size: 1rem;
}

.text-1_2 {
  font-size: 1.2rem;
}

.text-1_125 {
  font-size: 1.125rem;
}

.text-1_6 {
  font-size: 1.6rem;
}

.text-1_7 {
  font-size: 1.7rem;
}

.text-1_8 {
  font-size: 1.8rem;
}

.text-2 {
  font-size: 2rem;
}

.text-2_25 {
  font-size: 2.25rem;
}

.text-2_5 {
  font-size: 2.5rem;
}

.text-white {
  color: white;
}

.text-black {
  color: black !important;
}

.hover\:text-white {
  transition: .25s;

  &:hover {
    color: white !important;
    transition: .25s;
  }
}

.text-red {
  color: $error-red !important;
}

.text-emerald {
  color: $color-emerald;
}

.hover\:text-blue-500 {
  transition: .25s;

  &:hover {
    color: $color-blue-500 !important;
    transition: .25s;
  }
}

.text-gray-100 {
  color: $color-gray-100;
}

.text-heading-light {
  color: $heading-gray;
}

.text-lendesk-blue {
  color: $lendesk-blue;
}

.text-tia-maria {
  color: $tia-maria;
}

.hover\:text-lendesk-blue {
  transition: .25s;

  &:hover {
    color: $lendesk-blue !important;
    transition: .25s;
  }
}

.text-lendesk-blue-dark {
  color: $lendesk-blue-dark;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.color-apple-blossom {
  color: $color-apple-blossom;
}

.fw-bold {
  font-weight: bold !important;
}

.font-normal {
  font-weight: 400 !important;
}

.no-underline {
  text-decoration-line: none;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.align-middle {
  vertical-align: middle;
}

*,
::after,
::before {
  border: 0 solid #e5e7eb;
}

.shadow-2 {
  box-shadow: 0 2px 11px 0 rgb(127 127 136 / 13%);
}

.border {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-r {
  border-right-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-l-6 {
  border-left-width: 6px;
}

.border-black {
  border-color: black;
}

.border-iron {
  border-color: $color-iron;
}

.border-silver {
  border-color: $color-silver;
}

.border-gray-400 {
  border-color: $color-gray-400;
}

.border-blue-700 {
  border-color: $color-blue-700;
}

.border-blue-500 {
  border-color: $color-blue-500;
}

.border-blue-300 {
  border-color: $color-blue-300;
}

.border-red {
  border-color: $error-red !important;
}

.border-l-tradewind {
  border-left-color: $color-tradewind;
}

.border-lendesk-blue {
  border: solid 1px $lendesk-blue !important;
}

.border-l-purple {
  border-left-color: $color-purple;
}

.border-l-lochmara {
  border-left-color: $color-lochmara;
}

.border-lendesk-gray-blue {
  border-color: $lendesk-gray-blue !important;
}

.hover\:border-lendesk-blue {
  border: solid 1px $lendesk-blue !important;
}

.divide-x > * + * {
  --tw-divide-x-reverse: 0;

  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y > * + * {
  --tw-divide-y-reverse: 0;

  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-black > * + * {
  --tw-divide-opacity: 1;

  border-color: rgb(0 0 0 / var(--tw-divide-opacity));
}

.squared {
  border-radius: 2px;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-r {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.rounded-b {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-l {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.outline {
  outline-style: solid;
}

.outline-1 {
  outline-width: 1px;
}

.outline-transparent {
  outline-color: transparent;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-contain {
  background-size: contain;
}

.bg-white {
  background-color: white;
}

.bg-lendesk-light-blue {
  background-color: $lendesk-light-blue;
}

.hover\:bg-lendesk-light-gray {
  transition: .25s;

  &:hover {
    background-color: $lendesk-light-gray;
    transition: .25s;
  }
}

.hover\:bg-spotlight-orange-300 {
  transition: .25s;

  &:hover {
    background-color: $spotlight-orange-300;
    transition: .25s;
  }
}

.hover\:bg-lendesk-light-blue {
  transition: .25s;

  &:hover {
    background-color: $lendesk-light-blue !important;
    transition: .25s;
  }
}

.hover\:bg-white {
  transition: .25s;

  &:hover {
    background-color: white;
    transition: .25s;
  }
}

.bg-off-white {
  background-color: $off-white;
}

.bg-gray {
  background-color: #f5f5f5 !important;
  border: 1px solid #d2d3d4;
}

.bg-gray-100 {
  background-color: $color-gray-100;
}

.bg-gray-400 {
  background-color: $color-gray-400;
}

.bg-black {
  background-color: black;
}

.bg-tradewind {
  background-color: $color-tradewind;
}

.bg-yellow {
  background-color: $color-yellow;
}

.bg-green {
  background-color: $color-green;
}

.bg-purple {
  background-color: $color-purple;
}

.bg-purple-light {
  background-color: $color-purple-light;
}

.bg-red-100 {
  background-color: $color-red-100;
}

.bg-blue-200 {
  background-color: $color-blue-200;
}

.bg-spotlight-orange-500 {
  background-color: $spotlight-orange-500;
}

.bg-lendesk-blue {
  background-color: $lendesk-blue;
}

.bg-primary-light {
  background-color: #cae4f4 !important;
}

.hover\:bg-lendesk-blue {
  transition: .25s;

  &:hover {
    background-color: $lendesk-blue;
    transition: .25s;
  }
}

.hover\:bg-lendesk-blue-dark {
  transition: .25s;

  &:hover {
    background-color: $lendesk-blue-dark;
    transition: .25s;
  }
}

.bg-low {
  background-color: color.adjust($color-green, $lightness: 25%);
}

.bg-mid {
  background-color: color.adjust($spotlight-yellow, $lightness: 25%);
}

.bg-high {
  background-color: color.adjust($spotlight-orange-500, $lightness: 25%);
}

.bg-max {
  background-color: color.adjust($error-red, $lightness: 25%);
}

.from-dark-blue-to-light-blue {
  background-image: linear-gradient(to left, #006aa6 0%, #004b78 100%);
}

.bg-opacity-35 {
  opacity: .35;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.aspect-w-1,
.aspect-h-1 {
  position: relative;
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
}

[class*="aspect-w-"] > *,
[class*="aspect-h-"] > * {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.aspect-w-1 {
  --tw-aspect-w: 1;
}

.aspect-h-1 {
  --tw-aspect-h: 1;
}

// Hide an element visually without hiding it from screen reader
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

// Tailwind does have a class for the selected state, so it's based off of the `aria-selected` attribute
// For more details, see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-selected
[aria-selected="true"].selected\: {
  &bg-blue-200 {
    background-color: $color-blue-200;
  }

  &text-blue-700 {
    color: $color-blue-700;
  }
}

:hover.hover\: {
  &bg-gray-200 {
    background-color: $color-gray-200;
  }
}

:focus.focus\: {
  &z-0 {
    z-index: 0;
  }

  &outline-blue-600 {
    outline-color: rgb($color-blue-600);
  }

  &ring-4 {
    box-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }

  &ring-blue-600\/40 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(#{$color-blue-600} / 40%);
  }
}

.transform-rotate-180 {
  transform: rotate(180deg);
}

@media (min-width: 992px) {
  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:text-1 {
    font-size: 1rem;
  }

  .md\:mx-2 {
    margin-right: .5rem;
    margin-left: .5rem;
  }
}

@media (min-width: 1200px) {
  .lg\:gap-12 {
    gap: 3rem;
  }
}

@media (min-width: 1280px) {
  .xl\:flex-row {
    flex-direction: row;
  }
}

@media (min-width: 1680px) {
  .show-xl {
    display: block !important;
  }

  .hidden-xl {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .md\:flex {
    display: flex !important;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-6\/10 {
    width: 60%;
  }

  .md\:w-4\/10 {
    width: 40%;
  }

  .md\:mx-2 {
    margin-right: .5rem;
    margin-left: .5rem;
  }

  .md\:flex-basis-1\/4 {
    flex-basis: 25%;
  }
}

@media (max-width: 992px) {
  .lg\:flex-col {
    flex-direction: column;
  }
}

@media (max-width: 1200px) {
  .xl\:flex-basis-9\/20 {
    flex-basis: 45%;
  }

  .xl\:flex-basis-3\/10 {
    flex-basis: 30%;
  }

  .xl\:text-0_9 {
    font-size: .9rem;
  }
}

@media (max-width: 1024px) {
  .lg\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .lg\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .lg\:px-2vw {
    padding-right: 2vw;
    padding-left: 2vw;
  }
}

@media (max-width: 768px) {
  .md\:h-100 {
    width: 100% !important;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:flex-basis-1\/2 {
    flex-basis: 50%;
  }

  .md\:my-2 {
    margin-top: .2rem;
    margin-bottom: .2rem;
  }
}

@media (max-width: 576px) {
  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:my-2 {
    margin-top: .2rem;
    margin-bottom: .2rem;
  }

  .sm\:me-0 {
    margin-right: 0 !important;
  }
}

